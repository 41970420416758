@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";

.playAgain {
    background: white;
    @include border-radius(10px);
    padding: 30px;

    @media (min-width: $screen-medium) { 
        padding: 100px;
    }

    a {
        min-width: 240px;
    }
}

.logoXl {
    text-align: center;
    margin-bottom: 50px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-medium) {
        margin-bottom: 100px;
        max-width: 100%;
    }
}

.actions {
    @media (min-width: $screen-medium) {
        button {
            max-width: 240px;
        }
    }
}