@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";

.container {
  background: white;
  margin: 0 auto;
  @include border-radius(10px);
  max-width: 1000px;
  padding: 50px 70px;
  position: relative;
  width: 100%;

  @media (max-width: $screen-small) {
    padding: 30px;
  }
}

.masterWaiting {
  position: relative;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h2 {
    margin-bottom: 0;
  }
}

.qrCodeSmall {
  p {
    font-size: 10px;
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.qrCodeInner {
  cursor: pointer;
  font-size: 10px;
  height: 80px;
  position: relative;
  width: 80px;

  &:hover {
    img {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &:after {
    background: rgba(34, 38, 37, .7);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 100%;
    @include transition(all, .35s, ease);
  }
}

.qrCodeInner {
  position: relative;
}

.logoSmall {
  align-items: center;
  background: white;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  position: absolute;
  @include border-radius(50%);
  right: 0;
  top: 50%;
  width: 30px;
  @include transform(translateY(-50%));

  img {
    max-width: 25px;
  }
}

.iconExpand {
  left: 0;
  margin: -8px auto 0 auto;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  visibility: hidden;
  z-index: 1;
  @include transition(all, .35s, ease);
}

.content {
  margin: 50px auto;
  min-height: 300px;
}

.userList {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $screen-small) {
    gap: 20px;
    justify-content: center;
  }
}

.item {
  flex: 0 0 14.28%;
  margin: 15px 0;
}

.name {
  @include border-radius(10px);
  align-items: center;
  background: white;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 100px;
  justify-content: center;
  line-height: 21px;
  margin: 0 auto;
  @include box-shadow(0, 2px, 30px, rgba(0, 0, 0, 0.05));
  position: relative;
  width: 100px;

  &:hover {
    border-color: rgba(190, 190, 190, 0.5);
  }
  
  img {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
  }
}

.active {
  > div {
    background: #fe7762;
    color: white;

    &:hover {
      border: #fe7762;
    }
  }
}

.noPlayer {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
  min-height: 300px;
  width: 100%;
}

.qrCodePopup {
  @include border-radius(10px);
  align-items: center;
  background: rgba(34, 38, 36, .7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.popUpContent {
  background: white;
  max-width: 460px;
  padding: 80px;
  position: relative;
  @include border-radius(10px);
  width: 100%;

  @media (max-width: $screen-small) {
    padding: 60px 40px;
  }
}

.close {
  cursor: pointer;
  height: 22px;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 22px;
}

.qrCodeLogo {
  position: relative;

  p {
    align-items: center;
    background: white;
    display: flex;
    height: 50px;
    justify-content: center;
    left: 0;
    @include border-radius(50%);
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 50px;
    @include transform(translateY(-50%));

    img {
      max-width: 35px;
    }
  }
}
