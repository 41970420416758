@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";

.createGame {
  background: white;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  @include border-radius(10px);
  padding: 30px;
  @media (min-width: $screen-medium) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.btnGroup {
  @media (min-width: $screen-medium) {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }

  > a {
    margin: 10px;
  }
}
