.waitingLandscape {
  color: #61c38d;
  text-align: center;

  p {
    text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #61c38d 0%,
      #96d9a7 29%,
      #bceac8 67%,
      #ddfde6 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #61c38d;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 20px;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
}

.ldsEllipsis {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #61c38d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
