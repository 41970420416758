@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/screen";

.headerDashboard {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 0 0 0;

  .headerDashboardInr {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
  }
}

.hdrLeft {
  position: relative;
  display: block;
  flex: 0 0 50px;
  max-width: 50px;
}

.hdrRight {
  display: flex;
  align-items: center;
  gap: 40px;
}

.backHome {
  position: relative;
  font-size: 14px;
  color: #222624;
  font-weight: 500;
  padding-left: 25px;
  background: url(../../../assets/img/arrow-left.svg) no-repeat left center;
  background-size: 16px auto;
  text-decoration: none;
}

.hdrItem {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.user {
  font-size: 0;

  .avt {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 36px;
    @include border-radius(50%);

    &::before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .extraUser {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;

    .txtUser {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: #222624;
      line-height: 1.2;
      margin-bottom: 5px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .colorGray {
      color: #788180;
    }
  }
}

.userPopup {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  min-width: 180px;
  background-color: #fff;
  border: 1px solid #222624;
  padding: 10px 0;
  margin-top: 10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    list-style: none;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 5px;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #222624;
      line-height: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section {
  position: relative;
  display: block;
  width: 100%;
}

.sectionInr {
  position: relative;
  display: block;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 25px 0;
}

.title {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  color: #222624;
  margin-bottom: 15px;
  align-items: center;
  z-index: 3;
}

.title h2 {
  margin: 0;
}

.flexBox {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.flexItem {
  position: relative;
}

.subTitle {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222624;
  margin-bottom: 10px;
}

.chart {
  position: relative;
  display: block;
  width: 102%;
  height: 632px;
  margin: 0 auto 0 -0.9%;

  canvas {
    width: 100% !important;
    @include border-radius(45px);
  }
}

.itemQuadrants {
  position: absolute;
  width: fit-content;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #222624;
  pointer-events: none;
}

.itemQuadrants:nth-child(1) {
  top: 20px;
  left: 20px;
}

.itemQuadrants:nth-child(2) {
  top: 20px;
  right: 20px;
}

.itemQuadrants:nth-child(3) {
  left: 20px;
  bottom: 20px;
}

.itemQuadrants:nth-child(4) {
  right: 20px;
  bottom: 20px;
}

.selectBox {
  position: relative;
  display: flex;
  flex: 0 0 160px;
  max-width: 160px;
  justify-content: flex-end;
}

.selectHeader {
  position: relative;
  height: 33px;
  line-height: 35px;
  width: 112px;
  border: 1px solid rgba(190, 190, 190, 0.5);
  color: #222624;
  padding: 0 15px 0 12px;
  @include border-radius(5px);
  cursor: pointer;

  span {
    position: relative;
    top: -0.2vw;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    line-height: 1;
    max-width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #222624;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -8px;
    width: 15px;
    height: 15px;
    background: url(../../../assets/img/down-small.svg) no-repeat center center;
    background-size: 100% auto;
  }
}

.selectCnt {
  position: absolute;
  right: 0;
  top: 100%;
  width: auto;
  min-width: 157px;
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #bebebe80;
  @include border-radius(5px);
}

.selectItems {
  position: relative;
  display: block;
}

.itemSl {
  position: relative;
  display: block;
  margin-bottom: 6px;
}

.selectHint {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #788180;
}

.ItemsAxis {
  .itemAxis {
    position: absolute;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    color: #000000;

    &:first-child {
      left: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:last-child {
      left: 50%;
      bottom: 100%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

.boxCenter {
  position: relative;
  width: 100%;
  top: 170px;
}

@media screen and (max-width: 1240px) {
  .headerDashboard {
    .headerDashboardInr {
      width: 96%;
      max-width: 1150px;
    }
  }

  .sectionInr {
    width: 96%;
    max-width: 1150px;
  }
}

@media screen and (max-width: $screen-medium) {
  // Header
  .headerDashboard {
    .headerDashboardInr {
      width: 90%;
    }
  }

  .hdrLeft {
    flex: 0 0 40px;
    max-width: 40px;
  }

  .hdrRight {
    gap: 15px;
  }

  .hdrItem a {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 20px;
  }

  .user .avt {
    vertical-align: middle;
    width: 30px;
  }

  .user .extraUser .txtUser {
    font-size: 12px;
  }

  .sectionInr {
    width: 90%;
  }

  .title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .chart {
    width: 110%;
    margin: 0 auto 0 -5%;
  }

  .itemAxis {
    display: none;
  }
}
