@import "src/assets/styles/variables/color";

.messageNotification {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  margin: 10px 0;
  color: $color-red;

  img {
    margin-right: 6px;
  }
}
