@import "assets/styles/styles";

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  color: #222624;
  font-size: 16px;
  line-height: 24px;
}

p {
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 20px 0;
}

.container {
  max-width: 1270px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-sm {
  max-width: 375px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.content-fix {
  margin: 0 auto;
  min-height: calc(100vh - 400px);
  padding-top: 90px;
}

img {
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;

  img {
    margin-left: auto;
    margin-right: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 30px;
  color: black;
  font-weight: 500;
  line-height: 30px;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.d-flex {
  display: flex;
}

.fw-500 {
  font-weight: 500;
}

.message-error {
  color: #e51111;
}

.min-vh {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.checkbox {
  position: relative;
}

.checkbox label {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-weight: 400;
  padding-left: 8px;
  cursor: pointer;
}

.checkbox label strong,
.checkbox label a {
  font-weight: 700;
  color: #323232;
}

.checkbox label::before,
.checkbox label::after,
.checkbox input[type="checkbox"],
.checkbox.has_radio label::before,
.checkbox.has_radio label::after,
.checkbox input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.checkbox.has_radio input[type="radio"] + label:before {
  background: initial;
  opacity: initial;
  visibility: initial;
  margin-top: initial;
}

.checkbox.has_radio input[type="radio"] + label:after {
  border: initial;
}

.checkbox.has_radio input[type="radio"] + label:after {
  @include border-radius(3px);
  top: 50%;
  margin-top: initial;
  background: url(../src/assets/img/radio.png) no-repeat center center;
  background-size: 18px auto;
  -webkit-transition: none;
  transition: none;
}

.checkbox label::before,
.checkbox.has_radio label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border: 1px solid #bebebe;
  @include border-radius(3px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox.has_radio input[type="radio"]:checked + label:before {
  border: 1px solid #fe7762;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox.has_radio label::before,
.checkbox.has_radio label::after {
  @include border-radius(50% !important);
}

.checkbox input {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  -webkit-appearance: none;
  opacity: 0;
  cursor: pointer;
}

.checkbox label::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  opacity: 0;
  background: url(../src/assets/img/checked.svg) no-repeat center center;
  background-size: 10px auto;
  background-color: #fe7762;
  @include border-radius(3px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  opacity: 1;
}

.checkbox.has_radio input[type="radio"] + label {
  display: inline-block;
  padding: 0 0 0 8px;
  position: static;
}

.checkbox.has_radio input[type="radio"] {
  display: inline-block;
  position: relative;
}

.mb-40 {
  margin-bottom: 40px;
}

.group-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}


@media screen and (max-width: 520px) {
  .checkbox.has_radio input[type=radio] + label {
    font-size: 12px;
  }
}