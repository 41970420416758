@import "src/assets/styles/variables/screen";
@import "src/assets/styles/variables/mixin";

.positionContent {
  margin: 40px 0 65px 0;
  min-height: 545px;

  @media (min-width: $screen-large) {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 0 40%;

      &:last-child {
        flex: 0 0 60%;
      }
    }
  }
}

.playerList {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.05);
  max-height: 504px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555555;
  }
}

.inner {
  > div {
    border-bottom: 1px solid #eee;
    padding: 22px;

    &:last-child {
      border: none;
    }

    &:hover {
      background: rgba(190, 190, 190, 0.2);
    }

    > p {
      margin: 0;
      padding: 0 20px;
    }
  }

  input {
    border: none;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
  }
}

.buttons {
  display: flex;

  button {
    background: transparent;
    margin: 0 5px;
    padding: 0;
    width: 20px;
  }
}

.positionFooter {
  button {
    margin: 40px auto;
  }
}
