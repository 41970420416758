@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";

.footer {
  border-top: 0.5px solid #bebebe;
  padding: 30px 0;
  margin-top: 32px;
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;

  > div {
    flex: 0 0 50%;
    align-items: center;
  }

  p {
    align-items: center;
    display: flex;
    margin-bottom: 0;

    span {
      display: inline-block;
      margin: 0 7px;
    }
  }
}

.logoFt {
  margin-right: 30px;
  @media (max-width: $screen-small) {
    margin-right: 10px;
  }
}

.linkView {
  color: $color-black;
}
