@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";
@import "src/assets/styles/variables/color";

.managementList {
  @include border-radius(10px);
  @include box-shadow(0, 2px, 30px, rgba(0, 0, 0, 0.05));
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 40px;
  max-height: 557px;
  overflow: auto;
 
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555555;
  }

  > div {
    border-bottom: 1px solid #EEEEEE;
    padding: 24px;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    @media (min-width: $screen-medium) {
      display: flex;
      padding: 24px 70px;
    }

    &:last-child {
      border: none;
    }
  }
}

.username {
  flex: 0 0 175px;
  width: 100%;
  padding-right: 10px;
}

input.linkLogin {
  border: none;
  text-overflow: ellipsis;

  @media (min-width: $screen-medium) {
    margin-right: 100px;
  }
}

.copyIcon {
  background: white;
  padding: 0;
  flex: 0 0 14px;
  height: 16px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: transparent;
    opacity: 1;
  }
}

.notFound {
  padding: 70px;
}

.copiedText {
  background: $color-orange;
  color: white;
  display: inline-block;
  padding: 0 5px;
  font-size: 10px;
  position: absolute;
  top: -28px;
  left: -14px;
  visibility: hidden;
  opacity: 0;
}
