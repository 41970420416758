@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/screen";

.masterAccountList {
  margin-top: 50px;
}

.accountList {
  background: white;
  @include border-radius(10px);
  @include box-shadow(0, 2px, 30px, rgb(0 0 0 / 10%));
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  @media (min-width: 768px) {
    max-height: calc(100vh - 278px);
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  > div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      font-weight: bold;
    }

    > div {
      padding: 20px 50px;
      flex: 0 0 33.33%;
      width: 100%;

      @media (max-width: 786px) {
        padding: 15px 10px;
      }

      &:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:nth-child(2) {
        text-align: center;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);

  > div {
    background: white;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    @include border-radius(10px);
    @include box-shadow(0, 2px, 30px, rgba(0, 0, 0, 0.1));
    @media (max-width: 768px) {
      padding: 20px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.headerDashboard {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 0 0 0;
  z-index: 10;

  .headerDashboardInr {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
  }
}

.hdrLeft {
  position: relative;
  display: block;
  flex: 0 0 50px;
  max-width: 50px;
}

.hdrRight {
  display: flex;
  align-items: center;
  gap: 40px;
}

.backHome {
  position: relative;
  font-size: 14px;
  color: #222624;
  font-weight: 500;
  padding-left: 25px;
  background: url(../../../assets/img/arrow-left.svg) no-repeat left center;
  background-size: 16px auto;
  text-decoration: none;
}

.btnGlobal {
  display: inline-block;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #3d69b0;
  border: 1px solid #3d69b0;
  color: #fff;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  @include border-radius(5px);
}

.hdrItem {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.user {
  font-size: 0;

  .avt {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 36px;
    @include border-radius(50%);

    &::before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .extraUser {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;

    .txtUser {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: #222624;
      line-height: 1.2;
      margin-bottom: 5px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .colorGray {
      color: #788180;
    }
  }
}

.userPopup {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  min-width: 180px;
  background-color: #fff;
  border: 1px solid #222624;
  padding: 10px 0;
  margin-top: 10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    list-style: none;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 5px;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #222624;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $screen-medium) {
  // Header
  .headerDashboard {
    .headerDashboardInr {
      width: 90%;
    }
  }

  .hdrLeft {
    flex: 0 0 40px;
    max-width: 40px;
  }

  .hdrRight {
    gap: 10px;
  }

  .hdrItem a {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 20px;
    width: 80px;
  }

  .user .avt {
    vertical-align: middle;
    width: 30px;
  }

  .user .extraUser .txtUser {
    font-size: 12px;
  }

  .hdrItem a.backHome {
    height: auto;
    line-height: initial;
    padding: 0 0 0 22px;
  }

  // Section
  .sectionInr {
    width: 90%;
    padding: 20px 0;
  }

  .title {
    margin-bottom: 20px;
  }

  .flexBox {
    display: block;
    margin-bottom: 30px;
  }

  .flexItem {
    margin-bottom: 30px;
  }

  .col7 .flexItem:first-child,
  .col7 .flexItem:last-child {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .col7 .cntDashboard.chart {
    padding: 30px 20px 20px;
  }

  .selectHeader {
    line-height: 32px;
  }

  .col7 .ctnChart,
  .col5 .ctnChart {
    height: 300px;
  }

  .filterUser {
    right: 20px;
  }

  .radioBox {
    left: 55px;
    -webkit-transform: none;
    transform: none;
  }

  .col5 .flexItem {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .col5 .cntDashboard {
    padding: 30px 20px 20px;
  }
}