@import "src/assets/styles/variables/screen";

.textList {
  list-style: none;
  margin: 0;
  padding: 30px;

  @media (min-width: $screen-medium) {
    padding: 60px 90px;
  }

  li {
    align-items: center;
    display: flex;
    padding: 30px 0;
  }

  img {
    margin-right: 16px;
  }
}
