@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/mixin";

.slick-slider {
  .slick-arrow {
    bottom: -25px;
    color: $color-orange;
    font-size: 16px;
    height: auto;
    top: auto;
    width: auto;
    line-height: 100%;

    &:before {
      display: none;
    }

    &.slick-prev {
      color: $color-black;
    }
  }

  .slick-dots {
    left: 0;
    margin: 0 auto;
    max-width: 150px;
    right: 0;
  }
}

.slick-slide {
  padding-bottom: 20px;
  img {
    margin-bottom: 20px;
  }

  p {
    color: $color-gray;
    margin-bottom: 10px;
  }
}

.slick-arrow {
  &.slick-disabled {
    opacity: 0;
    visibility: hidden;
  }

  &.slick-next {
    color: $color-orange;
    right: 0;

    &:hover,
    &:focus {
      color: $color-orange;
    }
  }

  &.slick-prev {
    color: $color-black;
    left: 0;
  }
}

ul.slick-dots {
  li {
    button {
      border: 1px solid $color-orange;
      @include border-radius(50%);
      height: 10px;
      margin: 0 auto;
      width: 10px;

      &:before {
        display: none;
      }
    }

    &.slick-active {
      button {
        background: $color-orange;
      }
    }
  }
}
