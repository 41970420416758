.header-top {
  position: relative;

  .info-icon {
    position: absolute;
    right: 0;
    top: -14px;
    cursor: pointer;
    z-index: 10;
  }
}

.modal-information {
  background: white;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 50;

  .modal-content {
    padding-top: 65px;
    height: 100vh;
    overflow: auto;
  }
}

.modal-content {
  position: relative;
}

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 5;
}
