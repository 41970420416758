@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";

.form {
  font-size: 16px;
  margin: 0 auto;
  max-width: 820px;

  input {
    border: none;
    padding: 0;
    text-align: center;
    width: 100%;
  }
}


.has4Cols {
  .headingHarvester {
    > div {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .harvesters {
    > div {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.has3Cols {
  .headingHarvester {
    > div {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  .harvesters {
    > div {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
}

.has2Cols {
  .headingHarvester {
    > div {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .harvesters {
    > div {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.formHeading {
  display: flex;
  font-weight: 500;
  padding: 24px;
  text-align: center;
}

.headingName {
  width: 20%;
}

.headingHarvester {
  display: flex;
  width: 65%;

  > div {
    width: 100%;
  }
}

.headingTotal {
  width: 15%;
}

.formWrapper {
  background: white;
  @include border-radius(10px);
  @include box-shadow(0, 2px, 30px, rgba(0, 0, 0, 0.05));
  margin-bottom: 20px;
  margin-top: 40px;
  max-height: 585px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555555;
  }
}

.name {
  text-align: center;
}

.player {
  align-items: center;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 24px;

  > div {
    &:first-child {
      width: 20%;
    }

    &.harvesters {
      width: 65%;
    }

    &:last-child {
      width: 15%;
    }
  }
}

.harvesters {
  display: flex;
  text-align: center;

  > div {
    width: 100%;
  }
}

.harvester {
  > div {
    display: none;
  }
}

.total {
  display: flex;

  input {
    padding: 0;
    text-align: center;
    width: 100%;
  }

  button {
    background: transparent;
    color: #bebebe;
    height: 24px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
}

.actions {
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;

  button {
    margin: 0 10px;
    padding: 0 50px;
    width: auto;
  }
}

.resultOfPM {
  background: rgba(221, 253, 230, 1);
  padding: 15px 24px;
  margin-bottom: 20px;
  text-align: center;
  @include border-radius(10px);

  h4 {
    margin-bottom: 15px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
      font-size: 16px;
      flex: 0 0 40%;

      &:first-child {
        max-width: 20%;
      }
    }
  }
}
