@import "src/assets/styles/variables/color";

.timerForm {
  margin: 0 auto;
  max-width: 250px;
  min-height: calc(100vh - 400px);
  padding-top: 90px;

  input[type=number] {
    border: none;
    border-bottom: 1px solid $color-gray2;
    color: $color-black;
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    width: 100px;
    text-align: center;
    -moz-appearance: textfield;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: $color-black;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $color-black;
    }

    &::-ms-input-placeholder {
      color: $color-black;
    }
  }

  button {
    margin: 0 auto;
    width: auto;
  }
}

.inner {
  display: flex;

  > div {
    margin-bottom: 0;
  }
}

.spacing {
  font-size: 60px;
  font-weight: 500;
  line-height: 90px;
  width: 45px;
  text-align: center;
  flex: 0 0 45px;
}
