.main {
  display: flex;
  align-items: center;
  min-height: 100vh;

  > div {
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 480px;
  }
}

.description {
  text-align: center;
  padding: 40px 0;

  p {
    margin-bottom: 0;
  }
}

.logo {
  max-width: 100px;
  margin: 0 auto;
}
