@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/screen";

.headerDashboard {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 0 0 0;
  z-index: 10;

  .headerDashboardInr {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
  }
}

.hdrLeft {
  position: relative;
  display: block;
  flex: 0 0 50px;
  max-width: 50px;
}

.hdrRight {
  display: flex;
  align-items: center;
  gap: 40px;
}

.backHome {
  position: relative;
  font-size: 14px;
  color: #222624;
  font-weight: 500;
  padding-left: 25px;
  background: url(../../../assets/img/arrow-left.svg) no-repeat left center;
  background-size: 16px auto;
  text-decoration: none;
}

.btnGlobal {
  display: inline-block;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #3d69b0;
  border: 1px solid #3d69b0;
  color: #fff;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  @include border-radius(5px);
}

.hdrItem {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.user {
  font-size: 0;

  .avt {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 36px;
    @include border-radius(50%);

    &::before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .extraUser {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;

    .txtUser {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: #222624;
      line-height: 1.2;
      margin-bottom: 5px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .colorGray {
      color: #788180;
    }
  }
}

.userPopup {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  min-width: 180px;
  background-color: #fff;
  border: 1px solid #222624;
  padding: 10px 0;
  margin-top: 10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    list-style: none;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 5px;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #222624;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section {
  position: relative;
  display: block;
  width: 100%;
}

.sectionInr {
  position: relative;
  display: block;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 25px 0;
}

.title {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  color: #222624;
  margin-bottom: 25px;
}

.flexBox {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.flexItem {
  position: relative;
}

.subTitle {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222624;
  margin-bottom: 10px;
}

.col7 .flexItem:first-child {
  flex: 0 0 37.6612%;
  max-width: 37.6612%;
}

.col7 .flexItem:last-child {
  flex: 0 0 57.41935%;
  max-width: 57.41935%;
}

.col5 .flexItem {
  flex: 0 0 47.58064%;
  max-width: 47.58064%;
}

.cntDashboard {
  position: relative;
  padding: 45px 20px;
  min-height: 370px;
  @include border-radius(10px);
  -webkit-box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.col7 .cntDashboard.chart {
  padding: 30px 30px 15px;
}

.col5 .cntDashboard {
  padding: 30px 20px 18px;
  min-height: 300px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statisticItems {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    position: relative;
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    gap: 10px;
    margin-bottom: 43px;
    align-items: center;

    &:nth-child(5),
    &:nth-child(6) {
      margin-bottom: 0;
    }
  }
}

.itemIcon {
  position: relative;
  flex: 0 0 48px;
  max-width: 48px;
  line-height: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.itemTxt {
  position: relative;

  .number {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #222624;
    margin-bottom: 0;
  }

  .brief {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #788180;
    margin-bottom: 0;

    @media (min-width: 786px) {
      white-space: nowrap;
    }
  }
}

.ctnChart {
  position: relative;
  display: block;
  width: 100%;
  height: 310px;
}

.ctnChart::before {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

.noData {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}

.col7 .ctnChart {
  height: 310px;
}

@media screen and (max-width: 1240px) {
  .headerDashboard {
    .headerDashboardInr {
      width: 96%;
      max-width: 1150px;
    }
  }

  .sectionInr {
    width: 96%;
    max-width: 1150px;
  }

  .cntDashboard {
    padding: 30px;
    min-height: 350px;
  }

  .itemTxt {
    .number {
      font-size: 18px;
      line-height: 25px;
    }

    .brief {
      font-size: 12px;
      line-height: 21px;
    }
  }

  .col5 .cntDashboard {
    min-height: 240px;
  }

  .col5 .cntDashboard {
    padding: 30px 45px 10px;
  }

  .col7 .cntDashboard.chart {
    padding: 40px 35px 10px;
  }
}

@media screen and (max-width: $screen-medium) {
  // Header
  .headerDashboard {
    .headerDashboardInr {
      width: 90%;
    }
  }

  .hdrLeft {
    flex: 0 0 40px;
    max-width: 40px;
  }

  .hdrRight {
    gap: 10px;
  }

  .hdrItem a {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 20px;
    width: 80px;
  }

  .user .avt {
    vertical-align: middle;
    width: 30px;
  }

  .user .extraUser .txtUser {
    font-size: 12px;
  }

  .hdrItem a.backHome {
    height: auto;
    line-height: initial;
    padding: 0 0 0 22px;
  }

  // Section
  .sectionInr {
    width: 90%;
    padding: 20px 0;
  }

  .title {
    margin-bottom: 20px;
  }

  .flexBox {
    display: block;
    margin-bottom: 30px;
  }

  .flexItem {
    margin-bottom: 30px;
  }

  .col7 .flexItem:first-child,
  .col7 .flexItem:last-child {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .col7 .cntDashboard.chart {
    padding: 30px 20px 20px;
  }

  .selectHeader {
    line-height: 32px;
  }

  .col7 .ctnChart,
  .col5 .ctnChart {
    height: 300px;
  }

  .filterUser {
    right: 20px;
  }

  .radioBox {
    left: 55px;
    -webkit-transform: none;
    transform: none;
  }

  .col5 .flexItem {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .col5 .cntDashboard {
    padding: 30px 20px 20px;
  }
}
