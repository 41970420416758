@import "src/assets/styles/variables/screen";
.logoSmall {
  max-width: 86px;
  margin: 0 auto 50px auto;
}

.loginMaster {
  max-width: 340px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: $screen-medium) {
    padding: 30px;
  }
}
