.logo {
  margin: 0 auto;
  text-align: center;
}

.border-radius {
  @include border-radius(10px);
}

.bg-white {
  background: white;
}

.box {
  @include border-radius(10px);
  background: white;

  @media (min-width: $screen-medium) {
    align-items: center;
    display: flex;
    position: relative;

    &:after {
      background: rgba(190, 190, 190, 0.5);
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      width: 1px;
    }
  }

  > div {
    flex: 0 0 50%;
    max-width: 100%;
  }
}

.has-bg {
  align-items: center;
  background-image: url("../../img/login-bg.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  padding: 15px 0;
}

.max-340 {
  margin-left: auto;
  margin-right: auto;
  max-width: 340px;
}

.max-300 {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.logo-small {
  margin: 0 auto 40px auto;
  max-width: 86px;
}

.helper-text {
  color: $color-gray;
  font-size: 14px;
  margin-top: 10px;
}

.btn-arrow {
  background: $color-orange;
  color: white;
  display: inline-block;
  padding: 16px 50px;
  @include border-radius(5px);
  text-decoration: none;

  span {
    background: url("../../img/arrow-white.png") no-repeat right center transparent;
    display: inline-block;
    padding-right: 26px;
  }
}

button {
  &.btn-arrow {
    height: auto;
    line-height: 1.5;
    display: inline-block;
    width: auto;
  }
}

.fw-medium {
  font-weight: 500;
}

.switch-toggle {
  margin: 0 10px;
  width: 30px;

  label {
    background: #bebebe;
    display: block;
    font-size: 0;
    @include border-radius(20px);
    height: 16px;
    position: relative;
    width: 30px;

    &:after {
      background: white;
      content: '';
      display: block;
      height: 10px;
      @include border-radius(50%);
      left: 0;
      margin: 3px;
      position: absolute;
      top: 0;
      width: 10px;
      @include transition(all, .35s, ease);
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
    height: 16px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 30px;
    z-index: 2;

    &:checked + label {
      background: $color-orange;

      &:after {
        left: auto;
        right: 0;
      }
    }
  }
}

.select-protected {
  .css-86xgut-singleValue {
    color: $color-orange;
    font-weight: bold;
  }
}

.text-orange {
  color: $color-orange;
}

.text-hint {
  color: #788180;
  margin: 10px 0;
}