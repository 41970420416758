@import "src/assets/styles/variables/mixin";

.welcomeContent {
    max-width: 620px;
    margin: 0 auto;
    background: white;
    padding: 30px 20px 20px 20px;
    @include border-radius(10px);
    text-align: center;

    > div {
        margin-bottom: 30px;
    }
}