@import "src/assets/styles/variables/mixin";

.waitingScreen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.waitingImg {
  margin-bottom: 40px;
  text-align: center;

  img {
    @include border-radius(11px);
  }
}
