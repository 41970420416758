@import "src/assets/styles/variables/screen";
.organizationBox {
  max-width: 620px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;

  @media (min-width: $screen-medium) {
    padding: 50px 140px;
  }
}

.logoSmall {
  max-width: 70px;
  margin: 0 auto 20px auto;
}


