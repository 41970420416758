@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";

.linkBox {
  background: white;
  @include border-radius(5px);
  @include box-shadow(0, 2px, 20px, rgba(0, 0, 0, 0.1));
  display: flex;
  margin: 20px auto 30px auto;
  max-width: 420px;
  padding: 6px;
  position: relative;

  input {
    border: none;
    color: #788180;
    font-weight: 500;
    padding: 0 16px;
  }

  button {
    flex: 0 0 48px;
    height: 48px;
    padding: 0;
    width: 48px;
  }
}

.qrCodeImg {
  height: 205px;
  margin: 30px auto;
  overflow: hidden;
  @include border-radius(10px);
  position: relative;
  width: 205px;
}

.logo {
  background: white;
  height: 50px;
  @include border-radius(50%);
  left: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  @include transform(translateY(-54%));
  top: 50%;
  width: 50px;
  margin: 0 auto;
}

.qrCodeGame {
  > div {
    padding: 30px;
  }
}

.copyText {
  display: flex;
  position: absolute;
  top: -40px;
  height: 32px;
  align-items: center;
  justify-content: center;
  right: -5px;
  font-size: 11px;
  width: 70px;
  color: white;
  background: $color-orange;
  visibility: hidden;
  opacity: 0;
  @include transition(all, .35s, ease);

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.startGame {
  margin: auto;
  max-width: 340px;
}
