@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";

.formPlayer {
  align-items: center;
  justify-content: space-between;

  > div {
    margin-bottom: 0;
  }
}

.playerName {
  width: 100%;
  max-width: 22%;
  flex: 0 0 22%;
}

.playerHarvest {
  display: flex;
  flex: 0 0 78%;
}

.playerPosition {
  display: flex;
  flex: 0 0 85%;
  width: 100%;
  flex-wrap: wrap;

  > div {
    margin: 0;
  }
}

.has2Cols {
  > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.has3Cols {
  > div {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.has4Cols {
  > div {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.positionInput {
  width: 44px;
  height: 36px;
  margin: 0 auto;
  border: 1px solid transparent;
  @include border-radius(5px);
  color: #222624;

  &.focus {
    border-color: $color-orange;
  }

  * {
    -ms-overflow-style: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  > p {
    margin: 0;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
  }
}

.playerActions {
  align-items: center;
  justify-content: center;
  flex: 0 0 15%;
  width: 100%;
  position: relative;
  z-index: 10;
  gap: 5px;

  button {
    padding: 0;
    background: transparent;
    height: auto;
    line-height: 100%;
  }
}

.threeDots {
  display: flex;
  margin: 0;

  > span {
    display: block;
    margin: 0 2px;
    width: 4px;
    height: 4px;
    @include border-radius(50%);
    background: #BEBEBE;
  }
}
