@import "src/assets/styles/variables/color";

.messageNotification {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  margin: 30px 0 0 0;

  > div {
    display: flex;
  }

  img {
    margin-right: 6px;
  }
}

.success {
  color: $color-blue;
}

.error {
  color: $color-red;
}
