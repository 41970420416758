@import "src/assets/styles/variables/mixin";

.box {
  background: white;
  width: 100%;
  max-width: 90%;
  min-height: 150px;
  @include box-shadow(0, 2px, 10px, rgba(0, 0, 0, 0.1));
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  @include transition(all, .35s, ease);
  @include transform(translateY(-50%));
  z-index: 10;

  &:after {
    content: '';
    display: block;
    background: url("../../../../../assets/img/polygon-icon.png") no-repeat 0 0 transparent;
  }

  > div {
    padding: 30px 20px;
  }
}

.title {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 24px 0;
}

.harvestList {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;

  li {
    list-style: none;
    width: 48px;
    height: 48px;
    @include border-radius(50%);
    background: white;
    @include box-shadow(0, 2px, 20px, rgba(0, 0, 0, 0.1));
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 5px;
    position: relative;

    &.active {
      background: #FE7762;
      color: white;
    }

    &.gray {
      background: rgba(190, 190, 190, 0.3);
    }
  }
}

.position {
  position: absolute;
  bottom: 0;
  font-size: 10px;
}
