@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";

.game-content-master {
  min-height: 545px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $screen-small) {
    zoom: 0.62;
    -moz-transform: scale(0.62);
  }
}

.game-content {
  margin: 30px 0;
}

.game-board {
  margin: 0 auto;
  max-width: 650px;
  padding: 65px 50px 40px 50px;
  position: relative;
  width: 100%;

  &.small {
    font-size: 14px;
    line-height: 21px;
    padding: 40px 23px;
    min-height: 320px;
    height: 320px;

    @media (max-width: $screen-XSmall) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .game-list {
      min-width: 300px;
      min-height: 82px;
      height: 100%;
      @include box-shadow(0, 0, 3px, rgba(0, 0, 0, 1));
    }

    .harvesters {
      max-height: 50px;
      overflow: hidden;
    }

    .game-item {
      .inner {
        max-height: 50px;
        overflow: hidden;
      }
    }
  }
}

.game-title-row {
  display: flex;
  position: absolute;
  top: 0;

  > div {
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    width: 110px;
  }
}

.game-title-col {
  left: 0;
  position: absolute;
  top: 64px;

  > div {
    align-items: center;
    display: flex;
    height: 110px;
    padding: 0 20px;
  }
}

.small {
  .game-title-row {
    > div {
      padding: 10px;
      width: 60px;
    }
  }

  .game-title-col {
    top: 40px;
    left: -12px;

    > div {
      height: 60px;
      padding: 0 10px;
    }
  }

  .game-item {
    height: 60px;
    width: 60px;
    flex: 0 0 60px;
    max-width: 20%;

    &::after {
      max-width: 58px;
      max-height: 58px;
    }
  }

  .animal-icon {
    > span {
      width: 20px;
      height: 25px;
    }

    &.new-born {
      > span {
        width: 18px;
        height: 18px;
        background-size: contain;
      }
    }
  }

  .harvesters > li {
    padding: 2px 1px;
    span {
      width: 8px;
      height: 8px;
    }
  }
}

.game-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-width: 550px;
  min-height: 440px;

  &.disabled {
    pointer-events: none;
  }
}

.game-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 110px;
  height: 110px;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border: none;
  position: relative;
  @include box-shadow(0, 0, 0, 1px white);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    outline: 2px solid #09361E;
    max-width: 108px;
    max-height: 108px;
  }

  &.active {
    z-index: 5;

    &::after {
      visibility: visible;
      opacity: 1;
    }
  }

  &.clicked {
    > div {
      visibility: visible;
      opacity: 1;
    }
  }
}

.game-view {
  height: 440px;
  position: relative;
  width: 550px;
  @include box-shadow(0, 0, 3px, rgba(0, 0, 0, 1));

  > div {
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.animal-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2px 0;

  > span {
    background: url("../../img/animal.png") no-repeat 0 0 transparent;
    display: block;
    height: 38px;
    width: 30px;
    background-size: contain;
    margin: 0 2px;
  }

  &.new-born {
    > span {
      background: url("../../img/animal-new-born.png") no-repeat 0 0 transparent;
      width: 23px;
      height: 25px;
    }
  }
}

.count-down {
  color: $color-orange;
  font-size: 60px;
  font-weight: 500;
  line-height: 90px;
  text-align: center;
  margin-bottom: 40px;

  span {
    display: inline-block;
    width: 100px;

    &.split {
     width: 45px;
    }
  }
}

.time-left {
  font-size: 20px;
  line-height: 30px;
}

.harvesters{
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > li {
    list-style: none;
    padding: 2px;

    span {
      display: block;
      width: 10px;
      height: 10px;
      @include border-radius(50%);
      background: #29513B;
      font-size: 0;
      color: transparent;
    }

    &.selected {
      span {
        background: $color-orange;
      }
    }
  }
}

.animals {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.harvest-result {
  @include border-radius(10px);
  @include box-shadow(0, 2px, 30px, rgba(0, 0, 0, 0.05));
  background: white;
  padding: 20px;

  ul {
    display: flex;
    padding: 0;
    margin: 0 0 10px 0;
    color: $color-orange;

    li {
      list-style: none;
      flex: 0 0 50%;
      width: 100%;
      max-width: 50%;
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .orange {
    color: $color-orange;
  }
}
