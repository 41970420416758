@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/screen";

.header {
  padding: 40px 0;
}

.inner {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: $screen-small) {
    display: block;
    text-align: center;
  }
}

.pageTitle {
  line-height: 45px;
  margin: 0;

  @media (max-width: $screen-small) {
    margin-bottom: 20px;
  }
}

.linkBox {
  background: white;
  @include border-radius(5px);
  border: 1px solid $color-black;
  color: $color-black;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 20px;
  text-decoration: none;
  display: block;

  @media (min-width: $screen-small) {
    position: absolute;
    right: 0;
  }
}

.stepWrap {
  padding-bottom: 20px;

  > div {
    padding: 10px 0;
    overflow: auto;
    
    &::-webkit-scrollbar {
      height: 4px; 
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      @include border-radius(4px);
    }
  
    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      @include border-radius(4px);
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555555;
    }
  }
}

.stepper {
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 21px;
  list-style: none;
  margin: 0;
  padding: 0 0 30px 0;
  position: relative;
  width: 1240px;

  @media (max-width: $screen-small) {
    padding-bottom: 20px;
  }

  &:before {
    //background: rgba(190, 190, 190, 0.5);
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    width: 75%;
  }

  > li {
    flex: 0 0 12.5%;
    text-align: center;
    width: 100%;
    position: relative;
    padding-top: 24px;
    color: $color-gray;

    &:before {
      border: 1px solid rgba(190, 190, 190, 0.5);
      content: '';
      display: block;
      height: 14px;
      @include border-radius(50%);
      width: 14px;
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: white;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: rgba(190, 190, 190, 0.5);
      position: absolute;
      left: 50%;
      top: 0;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.__current {
      color: $color-orange;

      &:before {
        border-color: $color-orange;
      }
    }

    &.__pass {
      color: $color-black;

      &:before {
        border-color: $color-orange;
        background: $color-orange;
      }

      &:after {
        background: $color-orange;
      }
    }
  }
}

