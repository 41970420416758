@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
  border: none;
  border-bottom: 1px solid rgba(190, 190, 190, 0.5);
  color: $color-black;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  padding: 10px 0;
  width: 100%;
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.btn {
  display: block;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  padding: 0 40px;
  text-align: center;
  text-decoration: none;
  @include border-radius(5px);

  &.action {
    height: auto;
    line-height: 0;
    padding: 0 5px;
    width: auto;
    background: transparent;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }

  &.cancel {
    background: white;
    border: 1px solid #E51111;
    color: #E51111;
  }
}

.primary {
  background: #fe7762;
  border: 1px solid #fe7762;
  color: white;

  &:hover {
    background-color: rgba(254, 119, 98, .7);
    border-color: rgba(254, 119, 98, .7);
  }
}

.secondary {
  background: white;
  border: 1px solid #fe7762;
  color: #fe7762;

  &:hover {
    color: rgba(254, 119, 98, .7);
    border-color: rgba(254, 119, 98, .7);
    background: white;
  }
}

button {
  background: rgba(254, 119, 98, 1);
  border: none;
  color: white;
  @include border-radius(5px);
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 55px;
  line-height: 55px;
  margin: 0;
  padding: 0 40px;
  width: 100%;

  &:hover,
  &:focus {
    background: rgba(254, 119, 98, 0.7);
    border-color: rgba(254, 119, 98, 0.7);
    box-shadow: none;
    outline: none;
    color: white;
  }

  &:disabled {
    background: rgba(190, 190, 190, 0.5);
    border-color: transparent;
    cursor: not-allowed;
    opacity: 0.8;
    @include box-shadow(4px, 0, 4px, rgba(255, 255, 255, 0.25));
  }

  &.auto {
    width: auto;
  }
}

textarea {
  background: white;
  border: 1px solid rgba(190, 190, 190, 0.5);
  @include border-radius(5px);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $color-black;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  height: 130px;
  line-height: 21px;
  overflow: auto;
  padding: 10px;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

input[type="radio"] {
  opacity: 0;
  position: absolute;

  &:checked {
    &+label {
      &:after {
        border-color: #fe7762;
      }

      &:before {
        opacity: 1;
        visibility: visible;
        @include transform(scale(1));
      }
    }
  }

  &+label {
    align-items: center;
    display: flex;
    padding: 0 26px;
    position: relative;
    text-transform: capitalize;

    &:after,
    &:before {
      content: "";
      display: block;
      @include border-radius(50%);
      left: 0;
      margin-top: 3px;
      position: absolute;
      top: 0;
      @include transition(all, 0.35s, ease);
    }

    &:after {
      border: 2px solid rgba(190, 190, 190, 0.5);
      height: 18px;
      width: 18px;
    }

    &:before {
      background: #fe7762;
      height: 8px;
      left: 5px;
      opacity: 0;
      top: 5px;
      visibility: hidden;
      width: 8px;
      @include transform(scale(0));
    }
  }

  &:disabled {
    &+label {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }
}

.error {
  color: $color-red;

  input {
    border-bottom-color: $color-red;
  }
}

.form-item {
  margin: 0 0 35px 0;
  position: relative;

  &.disabled {
    pointer-events: none;
  }
}

.form-actions {
  margin-top: 40px;

  &.form-flex {
    display: flex;
    gap: 30px;
  }
}

.autocomplete-list {
  background: white;
  border: 1px solid rgba(190, 190, 190, 0.5);
  color: $color-black;
  font-size: 14px;
  left: 0;
  margin: 0;
  margin-top: 10px;
  max-height: 117px;
  overflow: auto;
  -ms-overflow-style: none;
  padding: 0;
  @include border-radius(5px);
  position: absolute;
  right: 0;
  scrollbar-width: none;
  top: 100%;
  z-index: 50;

  &::-webkit-scrollbar {
    display: none;
  }

  >li {
    border: none;
    cursor: pointer;
    list-style: none;
    padding: 7px 15px;

    &:hover {
      background: $color-green;
    }
  }
}

label {
  .deer {
    background: url("../../img/deer-icon.svg") no-repeat 0 0 transparent;
    display: inline-block;
    font-size: 0;
    height: 38px;
    line-height: 0;
    width: 30px;
    @include transform(translateY(-40%));
  }

  .bird {
    background: url("../../img/bird-icon.svg") no-repeat 0 0 transparent;
    display: inline-block;
    font-size: 0;
    height: 30px;
    line-height: 0;
    width: 33px;
    @include transform(translateY(-25%));
  }

  .elephant {
    background: url("../../img/elephant-icon.svg") no-repeat 0 0 transparent;
    display: inline-block;
    font-size: 0;
    height: 30px;
    line-height: 0;
    width: 43px;
    @include transform(translateY(-30%));
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-gray;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-gray;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $color-gray;
}
