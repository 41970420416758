@import "src/assets/styles/variables/mixin";
@import "src/assets/styles/variables/color";
@import "src/assets/styles/variables/screen";

.headerDashboard {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 0 0 0;
  z-index: 10;

  .headerDashboardInr {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
  }
}

.hdrLeft {
  position: relative;
  display: block;
  flex: 0 0 50px;
  max-width: 50px;
}

.hdrRight {
  display: flex;
  align-items: center;
  gap: 40px;
}

.backHome {
  position: relative;
  font-size: 14px;
  color: #222624;
  font-weight: 500;
  padding-left: 25px;
  background: url(../../../assets/img/arrow-left.svg) no-repeat left center;
  background-size: 16px auto;
  text-decoration: none;
}

.btnGlobal {
  display: inline-block;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #fe7762;
  border: 1px solid #fe7762;
  color: #fff;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  @include border-radius(5px);
}

.hdrItem {
  position: relative;
  display: inline-block;
  cursor: pointer;

  a {
    height: 41px;
    line-height: 41px;
  }
}

.user {
  font-size: 0;

  .avt {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 36px;
    @include border-radius(50%);

    &::before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .extraUser {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;

    .txtUser {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: #222624;
      line-height: 1.2;
      margin-bottom: 5px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .colorGray {
      color: #788180;
    }
  }
}

.userPopup {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  min-width: 180px;
  background-color: #fff;
  border: 1px solid #222624;
  padding: 10px 0;
  margin-top: 10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    list-style: none;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 5px;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #222624;
      line-height: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section {
  position: relative;
  display: block;
  width: 100%;
}

.sectionInr {
  position: relative;
  display: block;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 25px 0;
}

.title {
  position: relative;
  display: block;
  margin-bottom: 25px;
  z-index: 2;
}

.flexBox {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.flexItem {
  position: relative;
}

.subTitle {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222624;
  margin-bottom: 10px;
}

.col7 .flexItem:first-child {
  flex: 0 0 37.6612%;
  max-width: 37.6612%;
}

.col7 .flexItem:last-child {
  flex: 0 0 57.41935%;
  max-width: 57.41935%;
}

.col5 .flexItem {
  flex: 0 0 47.58064%;
  max-width: 47.58064%;
}

.cntDashboard {
  position: relative;
  padding: 45px;
  min-height: 390px;
  @include border-radius(10px);
  -webkit-box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.col7 .cntDashboard.chart {
  padding: 60px 45px 15px;
}

.col5 .cntDashboard {
  padding: 30px 30px 18px;
  min-height: 300px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statisticItems {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    position: relative;
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    gap: 10px;
    margin-bottom: 43px;
    align-items: center;

    &:nth-child(5),
    &:nth-child(6) {
      margin-bottom: 0;
    }
  }
}

.statisticItemsLeft,
.statisticItemsRight {
  flex: 0 0 47%;
  max-width: 47%;

  .item {
    flex: 0 0 100%;
    max-width: 100%;

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}

.statisticItemsRight {
  padding-left: 10px;
}

.hasLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  margin-left: -2%;
  background-color: rgba(190, 190, 190, 0.5);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.smallTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #000;
  margin-bottom: 20px;
}

.listItems {
  position: relative;
  display: block;
}

.itemIcon {
  position: relative;
  flex: 0 0 48px;
  max-width: 48px;
  line-height: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.itemTxt {
  position: relative;

  .number {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #222624;
    margin-bottom: 0;
  }

  .brief {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #788180;
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.ctnChart {
  position: relative;
  display: block;
  width: 100%;
}

.ctnChart::before {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

.noData {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}

.selectBox {
  position: relative;
  display: flex;
  flex: 0 0 160px;
  max-width: 160px;
  justify-content: flex-end;
}

.selectHeader {
  position: relative;
  height: 33px;
  line-height: 35px;
  width: 112px;
  border: 1px solid rgba(190, 190, 190, 0.5);
  color: #222624;
  padding: 0 15px 0 12px;
  @include border-radius(5px);
  cursor: pointer;

  span {
    position: relative;
    top: -0.2vw;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    line-height: 1;
    max-width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #222624;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -8px;
    width: 15px;
    height: 15px;
    background: url(../../../assets/img/down-small.svg) no-repeat center center;
    background-size: 100% auto;
  }
}

.selectBox.headerTitle {
  display: inline-block;
}

.headerTitle {
  .selectHeader {
    height: auto;
    line-height: 1.4;
    width: auto;
    padding: 0;
    border: none;

    span {
      font-size: 20px;
    }

    &::after {
      right: auto;
      left: 100%;
      margin: -2px 0 0 5px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.selectCnt {
  position: absolute;
  left: 0;
  top: 100%;
  width: auto;
  min-width: 185px;
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #bebebe80;
  @include border-radius(5px);
}

.selectItems {
  position: relative;
  display: block;
}

.itemSl {
  position: relative;
  display: block;
  margin-bottom: 6px;
}

.selectHint {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #788180;
}

.filterUser {
  position: absolute;
  right: 45px;
  top: 20px;
  z-index: 2;

  .selectBox {
    display: inline-block;
  }

  .selectHeader {
    width: 75px;
  }

  .selectCnt {
    right: 0;
    left: auto;
    min-width: 130px;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.emptyData {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #000;
  font-size: 18px;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
}

.hideUserCheckbox .itemSl:not(:first-child) {
  opacity: 0.5;
  pointer-events: none;
}

.hideAll .itemSl:first-child {
  opacity: 0.5;
  pointer-events: none;
}

.radioBox {
  position: absolute;
  top: 27px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
}

.flexRadio {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .itemSl {
    margin-bottom: 0;
  }
}

.col7 .ctnChart {
  height: 310px;
}

@media screen and (max-width: 1240px) {
  .headerDashboard {
    .headerDashboardInr {
      width: 96%;
      max-width: 1150px;
    }
  }

  .sectionInr {
    width: 96%;
    max-width: 1150px;
  }

  .cntDashboard {
    padding: 30px;
    min-height: 370px;
  }

  .itemTxt {
    .number {
      font-size: 18px;
      line-height: 25px;
    }

    .brief {
      font-size: 12px;
      line-height: 21px;
    }
  }

  .col5 .cntDashboard {
    min-height: 240px;
  }

  .col5 .cntDashboard {
    padding: 30px 45px 10px;
  }

  .col5 .emptyData {
    -webkit-transform: translateY(80px);
    transform: translateY(80px);
  }
}

@media screen and (max-width: $screen-medium) {
  // Header
  .headerDashboard {
    .headerDashboardInr {
      width: 90%;
    }
  }

  .hdrLeft {
    flex: 0 0 40px;
    max-width: 40px;
  }

  .hdrRight {
    gap: 15px;
  }

  .hdrItem a {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 20px;
  }

  .user .avt {
    vertical-align: middle;
    width: 30px;
  }

  .user .extraUser .txtUser {
    font-size: 12px;
  }

  // Section
  .sectionInr {
    width: 90%;
    padding: 20px 0;
  }

  .title {
    margin-bottom: 20px;
  }

  .flexBox {
    display: block;
    margin-bottom: 30px;
  }

  .flexItem {
    margin-bottom: 30px;
  }

  .col7 .flexItem:first-child,
  .col7 .flexItem:last-child {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .col7 .cntDashboard.chart {
    padding: 80px 20px 20px;
  }

  .selectHeader {
    line-height: 32px;
  }

  .col7 .ctnChart {
    height: 300px;
  }

  .filterUser {
    right: 20px;
  }

  .radioBox {
    left: 55px;
    -webkit-transform: none;
    transform: none;
  }

  .col5 .flexItem {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .col5 .cntDashboard {
    padding: 30px 20px 20px;
  }
}
